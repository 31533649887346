import classNames from 'classnames'
import merge from 'lodash.merge'
import { Radar } from 'react-chartjs-2'
import { PointElement, RadialLinearScale, Chart, LineElement, Tooltip, Legend, TooltipItem } from 'chart.js'

import type { ChartDataset, ChartOptions } from 'chart.js/auto'

Chart.register(PointElement, RadialLinearScale, LineElement, Tooltip, Legend)

export interface RadarChartProps {
  className?: string
  style?: Object

  datasets: Array<ChartDataset<'radar'>>
  labels: string[][]
  options?: ChartOptions
}

export const RadarChart = ({
  className,
  style,

  datasets,
  labels,
  options = {}
}: RadarChartProps): JSX.Element => {
  const defaultRadarChartOptions = {
    elements: {
      line: {
        borderWidth: 2
      }
    },
    plugins: {
      legend: {
        display: true,
        title: {
          display: false,
          // TODO make legend title text in props?
          text: 'Countries'
        },
        position: 'chartArea' as const
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: (tooltip: TooltipItem<'radar'>) => {
            const value = tooltip.raw
            const country = tooltip.dataset.label
            // TODO: Resolve Invalid type "unknown" of template literal expression below
            return `${value}% in ${country}`
          }
        }
      }
    },
    scales: {
      r: {
        pointLabels: {
          font: {
            size: 12
          }
        },
        ticks: {
          // TODO is it safe to assume % in all contexts?
          // TODO this function could use some additional handling i think
          callback: function (value: any, index?: any, values?: any) {
            return `${value}%`
          },
          font: {
            size: 8
          }
        },
        angleLines: {
          display: true
        },
        suggestedMin: 0,
        suggestedMax: 100
      }
    }
  }

  //@ts-ignore
  RadarChart.defaultRadarChartOptions = defaultRadarChartOptions

  return (
    <Radar
      className={classNames('gt-radar-chart', className)}
      style={style}
      data={{ datasets, labels }}
      options={merge(defaultRadarChartOptions, options)}
    />
  )
}
export default RadarChart
